<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:38:37
 * @LastEditTime: 2022-12-08 09:34:55
 * @FilePath: \cloud-platform\src\components\bar\chart.vue
 * @Description: 
-->
<template>
  <div class="singal">
    <Echart :options="options" height="250px" width="100%"></Echart>
  </div>
</template>

<script>
// 近七日交易分析
import { TransactionAnalysis } from '@/api/monitors.js'
import Echart from '@/common/echart'
export default {
  data() {
    return {
      options: {},
      timer: '',
      cdata: [],
      barData: [],
      rateData: [],
      amount: [],
      myColor: [],
      maxs: 0,
    }
  },
  components: {
    Echart,
  },
  created() {
    this.analysis()
    this.timer = setInterval(this.analysis, 21600000)
  },
  methods: {
    async analysis() {
      await TransactionAnalysis({ id: this.$store.getters.getdadavid }).then(
        (data) => {
          this.cdata = data.data.data
          this.cdata.forEach((key) => {
            this.rateData.push(this.conversionTimeNew(key.totalDate))
          })
          for (let i = 0; i < this.cdata.length; i++) {
            this.barData.push(this.cdata[i].totalOrderMoney) //金额
            this.amount.push(this.cdata[i].totalOrderNum) //笔数
          }
          this.maxs = Math.max.apply(
            Math,
            this.cdata.map((item) => {
              return item.totalOrderNum
            })
          )
        }
      )
      this.deploy()
    },
    conversionTimeNew(timestamp) {
      // chuan时间戳的转换
      var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return MM + '/' + d
    },
    deploy() {
      this.options = {
        grid: {
          left: '4%',
          right: '2%',
          bottom: '0%',
          // top: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          x: 'center',
          data: ['交易笔数', '交易金额'],
          textStyle: {
            color: '#fff',
          },
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },
        xAxis: {
          type: 'category',
          data: this.rateData,
          nameTextStyle: {
            color: '#fff',
          },
          axisLabel: {
            textStyle: {
              show: true,
              color: '#fff',
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            // scale: true,
            name: '单位 : 笔',
            // splitNumber: 8,
            // max: this.maxs,
            min: 0,
            // boundaryGap: [0.2, 0.2],
            nameTextStyle: {
              color: '#fff',
            },
            axisLabel: {
              textStyle: {
                show: true,
                color: '#fff',
              },
            },
          },
          {
            type: 'value',
            scale: true,
            name: '单位 : 元',
            // splitNumber: 8,
            // max: 24000,
            min: 0,
            nameTextStyle: {
              color: '#fff',
            },
            axisLabel: {
              textStyle: {
                show: true,
                color: '#fff',
              },
            },
            // boundaryGap: [0.2, 0.2],
          },
        ],
        series: [
          {
            name: '交易笔数',
            type: 'line',
            smooth: true,
            // xAxisIndex: 0,
            // yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#fffe03',
              },
            },
            data: this.amount,
          },
          {
            name: '交易金额',
            type: 'bar',
            xAxisIndex: 0,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#06c4f5',
              },
            },
            data: this.barData,
          },
        ],
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style scoped lang="scss">
.single {
  height: 100%;
  width: 100%;
}
</style>