<!--
 * @Author: sunfei
 * @Date: 2022-04-25 16:02:17
 * @LastEditTime: 2022-12-06 14:27:14
 * @FilePath: \cloud-platform\src\components\scrollBoards\index.vue
 * @Description: 
-->
<template>
  <div class="single">
    <dv-scroll-board :config="config" style="height: 242px" />
  </div>
</template>

<script>
import { currentTimeTransaction } from '@/api/monitors.js'
import { sysDictData } from '@/api/dictionaries.js'
export default {
  data() {
    return {
      timer: '',
      i: 1,
      paytype: [],
      information: [],
      config: {},
    }
  },
  components: {},
  mounted() {
    this.Throughput()
    this.timer = setInterval(this.Throughput, 60000)
  },
  methods: {
    findQuery(code, list) {
      const item = list.find((item) => item.code == code)
      return item ? item.name : '现金支付'
    },
    Throughput() {
      sysDictData({ typeId: 86 }).then((res) => {
        // 支付方式
        this.paytype = res.data.data
      })
      currentTimeTransaction({ id: this.$store.getters.getdadavid }).then(
        (res) => {
          this.information = res.data.data
          var gather = []
          for (let i = 0; i < this.information.length; i++) {
            var time = this.conversionTimeNew(this.information[i].createTime)
            var type = this.findQuery(
              this.information[i].paidType,
              this.paytype
            )
            var condition = ''
            switch (this.information[i].payStatus) {
              case 1:
                condition = '支付中'
                break
              case 2:
                condition = '支付成功'
                break
              case 3:
                condition = '支付失败'
                break
            }
            var list = [
              `${this.information[i].pkName}`,
              `${type}`,
              '<span>' + time + '</span>',
              `${this.information[i].orderPayMoney}`,
            ]
            gather.push(list)
          }
          this.config = {
            waitTime: 1000,
            headerBGC: ['transparent'],
            oddRowBGC: ['transparent'],
            evenRowBGC: ['transparent'],
            header: ['停车场', '付款方式', '时间', '金额'],
            data: gather,
            columnWidth: [300, 300, 300, 100],
            align: ['center', 'center', 'center', 'center'],
          }
        }
      )
    },
    conversionTimeNew(timestamp) {
      // chuan时间戳的转换
      var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return MM + '/' + d + ' ' + h + ':' + m + ':' + s
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>


<style   lang="scss">
.dv-scroll-board {
  .header {
    .header-item {
      color: #568aea;
    }
  }
}
</style>
<style lang="postcss" scoped>
.single {
  width: 100%;
  height: 100%;
}
</style>
