<!--
 * @Author: sunfei
 * @Date: 2022-04-24 10:16:37
 * @LastEditTime: 2022-12-06 14:21:24
 * @FilePath: \cloud-platform\src\components\publicColumns\chart.vue
 * @Description: 
-->
<template>
  <div class="single">
    <Echart :options="options" height="100%" width="100%"></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import { PaymentList } from '@/api/monitors.js'
export default {
  data() {
    return {
      options: {},
      timer: '',
      i: 1000,
      cdata: {
        category: [],
        barData: [],
        rateData: [],
        salvProMax: [],
        myColor: [
          '#1089E7',
          '#F57474',
          '#56D0E3',
          '#F8B448',
          '#8B78F6',
          '#78bfdc',
        ],
      },
    }
  },
  components: {
    Echart,
  },
  mounted() {
    this.Throughput()
    this.timer = setInterval(this.Throughput, 21600000)
  },
  methods: {
    async Throughput() {
      await PaymentList({ id: this.$store.getters.getdadavid }).then((data) => {
        var total = data.data.data
        total = total
          .filter((item) => {
            return item.totalOrderMoney != 0
          })
          .slice(0, 6)

        this.cdata.barData = total.map((item, index) => {
          if (item.totalOrderMoney == null) {
            return 0
          }
          return item.totalOrderMoney
        })

        this.cdata.rateData = total.map((item, index) => {
          if (item.totalOrderNum == null) {
            return 0
          }
          return item.totalOrderNum
        })

        this.cdata.category = total.map((item, index) => {
          return item.pkName
        })
      })
      this.deploy()
    },
    deploy() {
      var salvProMax = [] //背景按最大值
      var datalist = this.cdata
      var maxCount = Math.max(...datalist.barData)
      for (let i = 0; i < datalist.barData.length; i++) {
        salvProMax.push(maxCount)
      }
      this.options = {
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
          formatter: (params) => {
            var res = ''
            for (var i = 0; i < params.length; i++) {
              res = `${
                params[0].name
              }<br /><span style="display:inline-block;position:relative; top:-3px;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${
                params[0].color
              }"></span>交易金额 : ${params[0].value}<br/>
                <span style="display:inline-block;position:relative; top:-3px;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:#f57474"></span>交易笔数 : ${
                  this.cdata.rateData[params[0].dataIndex]
                }
                `
            }
            return res
          },
        },
        xAxis: {
          show: false,
          type: 'value',
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff',
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: datalist.category,
          },
          {
            type: 'category',
            inverse: true,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#ffffff',
                fontSize: '12',
              },
            },
            data: datalist.barData,
          },
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: function (params) {
                  var num = datalist.myColor.length
                  return datalist.myColor[params.dataIndex % num]
                },
              },
            },
            barWidth: 10,
            data: datalist.barData,
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 10,
            barGap: '-100%',
            data: salvProMax,
            itemStyle: {
              normal: {
                color: '#014898',
                barBorderRadius: 30,
              },
            },
          },
        ],
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>


<style scoped lang="scss">
.single {
  height: 100%;
  width: 100%;
}
</style>