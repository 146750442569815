<!--
 * @Author: sunfei
 * @Date: 2022-04-15 08:46:53
 * @LastEditTime: 2022-12-06 14:19:40
 * @FilePath: \cloud-platform\src\views\Outerchain\onTheSameDayflow\index.vue
 * @Description: 
-->
<template>
  <div id="item">
    <dv-border-box-12>
      <div class="bgColorBlack">
        <div class="dFlex">
          <div class="title">
            <span class="text">今日车流量统计</span>
          </div>
        </div>
        <div class="data">
          <!-- <BottomLeftChart /> -->
          <div class="parkingLot">
            <div class="enter">
              <p>固定车辆统计</p>
              <p>{{ information.fixed }}</p>
            </div>
            <div class="out">
              <p>临时车辆统计</p>
              <p>{{ information.temp }}</p>
            </div>
          </div>
          <div class="vehicle">
            <div class="state">
              <div class="icon">
                <!-- <dashBoard /> -->
                <dv-decoration-9 class="item"
                  >{{ ratio.fixed }} %</dv-decoration-9
                >
              </div>
              <!-- <div class="quantity">
                <p>{{ information.fixed }}辆</p>
                <p>固定车辆统计</p>
              </div> -->
            </div>
            <div class="state">
              <div class="icon">
                <!-- <dashBoard /> -->
                <dv-decoration-9 class="item"
                  >{{ ratio.temp }} %</dv-decoration-9
                >
              </div>
              <!-- <div class="quantity">
                <p>{{ information.temp }}辆</p>
                <p>临时车辆统计</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import { TodaysVehicleTraffic } from '@/api/monitors.js'
import dashBoard from '@/components/dashBoard'
export default {
  data() {
    return {
      ratio: {
        fixed: 0,
        temp: 0,
      },
      information: {},
      timer: '',
    }
  },
  components: {
    dashBoard,
  },
  created() {
    this.gain()
    this.timer = setInterval(this.deploy, 86400000)
  },
  methods: {
    gain() {
      // 今日车流量统计
      TodaysVehicleTraffic({ id: this.$store.getters.getdadavid }).then(
        (data) => {
          this.information = data.data.data
          if (!this.information.temp == 0 || !this.information.fixed == 0) {
            var overall =
              Number(this.information.temp) + Number(this.information.fixed)
            this.ratio.fixed = Math.round(
              (Number(this.information.fixed) / overall) * 100
            )
            this.ratio.temp = Math.round(
              (Number(this.information.temp) / overall) * 100
            )
          }
        }
      )
    },
    deploy() {
      this.gain()
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
#item {
  height: calc(100% / 3);
  .bgColorBlack {
    box-sizing: border-box;

    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    .dFlex {
      display: flex;
      flex-direction: column;
      margin: 20px 20px 0 20px;
      .title {
        font-weight: 700;
      }
    }
    .data {
      flex: 1;

      .parkingLot {
        display: flex;
        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          text-align: center;
        }
        .enter {
          color: #0569b4;

          p {
            margin: 10px 0 0 0;
            font-weight: 700;
            font-size: 20px;
          }
        }
        .out {
          color: #0ee7e0;
          p {
            margin: 10px 0 0 0;
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
    }
    .vehicle {
      display: flex;
      box-sizing: border-box;
      .state {
        flex: 1;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .icon {
          flex: 1;
          display: flex;
          text-align: center;
          align-content: center;
          justify-content: center;
          .item {
            width: 140px;
            height: 140px;
            font-size: 20px;
          }
        }
        &:first-child {
          padding: 30px 0 30px 0;
        }
      }
    }
  }
}
</style>
