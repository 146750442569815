<!--
 * @Author: sunfei
 * @Date: 2022-04-15 08:46:53
 * @LastEditTime: 2022-04-29 13:12:08
 * @FilePath: \cloud-platform\src\views\Outerchain\realTimeRecord\index.vue
 * @Description: 
-->
<template>
  <div id="item">
    <dv-border-box-12>
      <div class="bgColorBlack">
        <div class="dFlex">
          <div class="ranking">
            <span class="text">近7日交易分析</span>
          </div>
        </div>
        <div class="title">
          <bars />
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>
  
<script>
import bars from '@/components/bar'
export default {
  components: {
    bars,
  },
  data() {
    return {
      config: {},
    }
  },
}
</script>

<style lang="scss" scoped>
#item {
  height: calc(100% / 3);
  .bgColorBlack {
    color: #fff;
    padding: 20px 20px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .dFlex {
      .ranking {
        .text {
          font-weight: 700;
        }
      }
    }
    .title {
      flex: 1;
    }
  }
}
</style>