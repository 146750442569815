<!--
 * @Author: sunfei
 * @Date: 2022-04-20 09:46:56
 * @LastEditTime: 2022-12-06 14:31:26
 * @FilePath: \cloud-platform\src\components\pie\chart.vue
 * @Description: 
-->

<template>
  <div class="single">
    <Echart :options="options" height="100%" width="100%  "></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import { amountStatistical } from '@/api/monitors.js'
export default {
  data() {
    return {
      options: {},
      moneyQuantity: {},
      datalist: [],
    }
  },
  components: {
    Echart,
  },
  mounted() {
    this.amount()
    this.timer = setInterval(this.amount, 21600000)
  },
  methods: {
    async amount() {
      await amountStatistical({ id: this.$store.getters.getdadavid }).then(
        (res) => {
          this.moneyQuantity = res.data.data
          this.datalist = Object.entries(this.moneyQuantity).map(
            ([key, value]) => {
              switch (key) {
                case 'cash':
                  key = '现金'
                  break
                case 'wxpay':
                  key = '微信'
                  break
                case 'alipay':
                  key = '支付宝'
                  break
                case 'hzcity':
                  key = '杭州城市大脑'
                  break
                case 'ccb':
                  key = '银行'
                  break
                case 'etc':
                  key = 'ETC'
                  break
              }

              return { name: key, value: value }
            }
          )
        }
      )
      this.deploy()
    },
    deploy() {
      this.options = {
        grid: {
          // left: '0%',
          // right: '0%',
          // bottom: '0%',
          top: '50',
          // containLabel: true,
        },
        legend: {
          bottom: 'bottom',
          // orient 设置布局方式，默认水平布局，可选值：'horizontal'（水平） ¦ 'vertical'（垂直）
          orient: 'horizontal',
          itemWidth: 15, // 设置图例图形的宽
          itemHeight: 15, // 设置图例图形的高
          textStyle: {
            color: '#666', // 图例文字颜色
          },
          // itemGap设置各个item之间的间隔，单位px，默认为10，横向布局时为水平间隔，纵向布局时为纵向间隔
          itemGap: 10,
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
          confine: true,
        },
        series: [
          {
            name: '支付',
            type: 'pie',
            center: ['60%', '60%'],
            avoidLabelOverlap: true,
            data: this.datalist,
            radius: 50,
            bottom: 20,
            label: {
              padding: [0, -20],
              show: true,
              position: 'outside',
              formatter: '{b}\n\n',

              rich: {
                text: {
                  fontSize: '12',
                  lineHeight: '0',
                  color: '#fff',
                },
                num: {
                  fontSize: '12',
                  lineHeight: '0',
                  color: '#fff',
                },
              },
            },
            // 线属性
            labelLine: {
              length: 15,
              length2: 20,
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = [
                    '#51b9f6',
                    '#6deaec',
                    '#99afe1',
                    '#c8346a',
                    '#c0956a',
                    '#c85f6a',
                  ]
                  return colorList[params.dataIndex]
                },
              },
            },
          },
        ],
      }
    },
  },
}
</script>


<style scoped lang="scss">
.single {
  height: 100%;
  width: 100%;
}
</style>