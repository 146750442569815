<!--
 * @Author: sunfei
 * @Date: 2022-04-18 16:03:56
 * @LastEditTime: 2022-04-29 10:47:32
 * @FilePath: \cloud-platform\src\views\Outerchain\total\index.vue
 * @Description: 
-->
<template>
  <div id="item">
    <dv-border-Box-13 class="mapList">
      <mapMonitoring></mapMonitoring>
    </dv-border-Box-13>
  </div>
</template>

<script>
import mapMonitoring from '@/components/map'
export default {
  components: {
    mapMonitoring,
  },
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
#item {
  height: 62%;
  .mapList {
    padding: 20px;
    box-sizing: border-box;
  }
}
</style>
