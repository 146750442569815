<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:38:44
 * @LastEditTime: 2023-03-28 09:27:47
 * @FilePath: \cloud-platform\src\components\map\chart.vue
 * @Description: 
-->
<template>
  <div class="single">
    <!-- 年度开工率 -->
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="100%"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
  import { QueryProvinceParkNum } from '@/api/monitors.js';
  import Echart from '@/common/echartMap';

  export default {
    data() {
      return {
        zoom: 1,
        amount: 0, //车场总数
        options: {},
        cdata: [],
        timer: '',
        provincial: [
          { name: '北京市', value: ['116.3979471', '39.9081726', 0] },
          { name: '上海市', value: ['121.4692688', '31.2381763', 0] },
          { name: '天津市', value: ['117.2523808', '39.1038561', 0] },
          { name: '重庆市', value: ['106.548425', '29.5549144', 0] },
          { name: '河北省', value: ['114.4897766', '38.0451279', 0] },
          { name: '山西省', value: ['112.5223053', '37.8357424', 0] },
          { name: '辽宁省', value: ['123.4116821', '41.7966156', 0] },
          { name: '吉林省', value: ['125.3154297', '43.8925629', 0] },
          { name: '黑龙江省', value: ['126.6433411', '45.7414932', 0] },
          { name: '浙江省', value: ['120.1592484', '30.265995', 0] },
          { name: '福建省', value: ['119.2978134', '26.0785904', 0] },
          { name: '山东省', value: ['117.0056', '36.6670723', 0] },
          { name: '河南省', value: ['113.6500473', '34.7570343', 0] },
          { name: '湖北省', value: ['114.2919388', '30.5675144', 0] },
          { name: '湖南省', value: ['112.9812698', '28.2008247', 0] },
          { name: '广东省', value: ['113.2614288', '23.1189117', 0] },
          { name: '海南省', value: ['110.3465118', '20.0317936', 0] },
          { name: '四川省', value: ['104.0817566', '30.6610565', 0] },
          { name: '贵州省', value: ['106.7113724', '26.5768738', 0] },
          { name: '云南省', value: ['102.704567', '25.0438442', 0] },
          { name: '江西省', value: ['115.8999176', '28.6759911', 0] },
          { name: '陕西省', value: ['108.949028', '34.2616844', 0] },
          { name: '青海省', value: ['101.7874527', '36.6094475', 0] },
          { name: '甘肃省', value: ['103.7500534', '36.0680389', 0] },
          { name: '宁夏回族自治区', value: ['108.3117676', '22.8065434', 0] },
          { name: '新疆维吾尔自治区', value: ['87.6061172', '43.7909393', 0] },
          { name: '内蒙古自治区', value: ['111.6632996', '40.8209419', 0] },
          { name: '西藏自治区', value: ['91.1320496', '29.657589', 0] },
          { name: '宁夏回族自治区', value: ['106.2719421', '38.4680099', 0] },
          { name: '台湾省', value: ['120.9581316', '23.8516062', 0] },
          { name: '香港特别行政区', value: ['114.139452', '22.391577', 0] },
          { name: '澳门特别行政区', value: ['113.5678411', '22.167654', 0] },
          { name: '安徽省', value: ['117.2757034', '31.8632545', 0] },
          { name: '江苏省', value: ['118.7727814', '32.0476151', 0] },
        ],
      };
    },
    created() {
      this.information();
      this.timer = setInterval(this.information, 21600000);
    },
    components: {
      Echart,
    },
    methods: {
      async information() {
        let nProvincial = [];
        await QueryProvinceParkNum({ id: this.$store.getters.getdadavid }).then(
          (res) => {
            if (window.matchMedia('(max-width: 1920px)').matches) {
              this.zoom = 1.5;
            }
            if (window.matchMedia('(max-width: 1680px)').matches) {
              this.zoom = 1.4;
            }
            if (window.matchMedia('(max-width: 1440px)').matches) {
              this.zoom = 1.3;
            }
            if (window.matchMedia('(max-width: 1366px)').matches) {
              this.zoom = 1.2;
            }
            if (window.matchMedia('(max-width: 1280px)').matches) {
              this.zoom = 1.1;
            }

            // 车场数
            var list = res.data.data;
            let dataMap = {};
            list.forEach((item) => {
              dataMap[item['name']] = item['num'];
            });
            this.provincial.forEach((item) => {
              const name = item['name'];
              if (dataMap[name] && dataMap[name] != 0) {
                item['value'][2] = dataMap[name];
                nProvincial.push(item);
              }
            });

            for (let index = 0; index < nProvincial.length; index++) {
              this.amount += nProvincial[index].value[2];
            }
            this.cdata = nProvincial;
          }
        );
        this.deploy();
      },
      deploy() {
        this.options = {
          title: [
            {
              text: `车 场 总 数 ${this.amount}`,
              textStyle: {
                color: '#fff',
              },
              left: 100,
              top: 50,
              textStyle: {
                color: '#fff',
                fontSize: '22',
              },
            },
            {
              text: '全 国 停 车 场 分 布 图',
              right: '3%',
              top: 20,
              textStyle: {
                color: '#fff',
                fontSize: '16',
              },
            },
          ],

          // 背景颜色
          // backgroundColor: '#181f3c',
          // 提示浮窗样式
          // tooltip: {
          //   show: true,
          //   trigger: 'item',
          //   alwaysShowContent: false,
          //   backgroundColor: '#0C121C',
          //   borderColor: 'rgba(0, 0, 0, 0.16);',
          //   hideDelay: 100,
          //   triggerOn: 'mousemove',
          //   enterable: true,
          //   textStyle: {
          //     color: '#DADADA',
          //     fontSize: '12',
          //     width: 20,
          //     height: 30,
          //     overflow: 'break',
          //   },
          //   showDelay: 100,
          //   formatter(params) {
          //     return `车场名称：${params.name}</br>地址: 浙江省杭州市祥圆路88号</br>车位数: 1000</br>
          //       剩余车位: 90</br>车位使用率: 89.21%</br>设备数量: 123(在线)</br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10{离线}
          //       </br>数值：${params.value[2]}`
          //   },
          // },
          // graphic: {
          //   // 水印类型
          //   type: 'text',
          //   // 相对于容器的位置
          //   left: '10%',
          //   top: '10%',
          //   // 样式设置
          //   style: {
          //     // 文本内容
          //     text: '全国停车场分布图',
          //     // 字体颜色
          //     fill: '#fff',
          //   },
          // },
          // 地图配置
          geo: {
            map: 'china',
            zoom: this.zoom,
            aspectScale: 0.8,
            center: [101.4038, 36.8207],
            roam: true,
            label: {
              // 通常状态下的样式
              normal: {
                show: true,
                textStyle: {
                  color: '#fff',
                },
              },
              // 鼠标放上去的样式
              emphasis: {
                textStyle: {
                  color: '#fff',
                },
              },
            },
            // 地图区域的样式设置
            itemStyle: {
              normal: {
                borderColor: 'rgba(147, 235, 248, 1)',
                borderWidth: 1,
                areaColor: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(147, 235, 248, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(147, 235, 248, .2)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
                shadowColor: 'rgba(128, 217, 248, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10,
              },
              // 鼠标放上去高亮的样式
              emphasis: {
                areaColor: '#389BB7',
                borderWidth: 0,
              },
            },
          },
          series: [
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              symbol: 'pin',
              aspectScale: 0.75,
              legendHoverLink: true,
              symbolSize: [60, 60],
              // 这里渲染标志里的内容以及样式
              label: {
                show: true,
                formatter(value) {
                  return value.data.value[2];
                },
                color: '#fff',
              },
              // 标志的样式
              itemStyle: {
                normal: {
                  color: 'rgba(255,0,0,.7)',
                  shadowBlur: 2,
                  shadowColor: 'D8BC37',
                },
              },
              data: this.cdata,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke',
              },
              hoverAnimation: true,
              zlevel: 1,
            },
          ],
        };
      },
    },
    beforeDestroy() {
      clearInterval(this.timer);
    },
  };
</script>

<style scoped lang="scss">
  .single {
    height: 100%;
    width: 100%;
  }
</style>
