/*
 * @Author: sunfei
 * @Date: 2022-04-20 13:45:05
 * @LastEditTime: 2022-12-01 16:33:02
 * @FilePath: \cloud-platform\src\api\monitors.js
 * @Description: 
 */
import {
    request
} from '../network/request.js'

export function onTheSameDayThroughput(query) { // 24小时车流量统计
    return request({
        url: '/parking/saas/bigData/findTotalInout24Hours',
        method: 'get',
        params: query,
    })
}
export function PaymentList(query) { // 24小时缴费排行
    return request({
        url: '/parking/saas/bigData/findTotalMoney24Hours',
        method: 'get',
        params: query,
    })
}
export function currentTimeAccess(query) { // 实时进出记录
    return request({
        url: '/parking/saas/bigData/realTimeInoutLog',
        method: 'get',
        params: query,
    })
}
export function currentTimeTransaction(query) { // 实时交易记录
    return request({
        url: '/parking/saas/bigData/realTimePayLog',
        method: 'get',
        params: query,
    })
}
export function TodaysVehicleTraffic(query) { // 今日车流量统计
    return request({
        url: '/parking/saas/bigData/findTotalInout',
        method: 'get',
        params: query,
    })
}

export function CheckParkingDetails(query) { // 根据停车场id查询停车详情
    return request({
        url: '/parking/saas/bigData/findByPkId',
        method: 'get',
        params: query,
    })
}
export function AllStoppingPlace(query) { // 获取所有停车场
    return request({
        url: '/parking/saas/pkInfo',
        method: 'get',
        params: query,
    })
}
export function QueryProvinceParkNum(query) { // 查询省的停车场数量
    return request({
        url: '/parking/saas/bigData/findProvincePkNum',
        method: 'get',
        params: query,
    })
}



export function TransactionAnalysis(query) { // 近七日交易分析
    return request({
        url: '/parking/saas/bigData/findTotalMoney7Day',
        method: 'get',
        params: query,
    })
}
export function TransactionDetails(query) { // 交易详情
    return request({
        url: '/parking/saas/bigData/findTotalMoney',
        method: 'get',
        params: query,
    })
}
export function amountStatistical(query) { // 饼图金额统计
    return request({
        url: '/parking/saas/bigData/payType',
        method: 'get',
        params: query,
    })
}