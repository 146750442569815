<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:38:44
 * @LastEditTime: 2022-04-28 14:19:41
 * @FilePath: \cloud-platform\src\components\map\index.vue
 * @Description: 
-->
<template>
  <div class="twain">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data() {
    return {
      cdata: {
        category: [],
        lineData: [],
        barData: [],
        rateData: [],
      },
    }
  },
  components: {
    Chart,
  },
  mounted() {
    this.setData()
  },
  methods: {
    // 根据自己的业务情况修改
    setData() {
      // for (let i = 0; i < this.cdata.barData.length - 1; i++) {
      //   let rate = this.cdata.barData[i] / this.cdata.lineData[i]
      //   this.cdata.rateData.push(rate.toFixed(2))
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.twain {
  width: 100%;
  height: 100%;
}
</style>