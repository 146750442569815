<!--
 * @Author: sunfei
 * @Date: 2022-04-14 10:50:03
 * @LastEditTime: 2022-11-28 12:57:13
 * @FilePath: \cloud-platform\src\common\echart\index.vue
 * @Description: 
-->
<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
export default {
  name: 'echart',
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    id: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    options: {
      handler(options) {
        // 设置true清空echart缓存
        this.chart.setOption(options, true)
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = this.$echarts.init(this.$el)
      this.chart.setOption(this.options, true)
      // 初始化echart
    },
  },
}
</script>

<style>
</style>
