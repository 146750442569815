<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:38:37
 * @LastEditTime: 2022-04-29 13:03:03
 * @FilePath: \cloud-platform\src\components\bar\index.vue
 * @Description: 
-->
<template>
  <div class="twain">
    <Chart />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data() {
    return {
      cdata: {
        category: [],
        barData: [],
        rateData: [],
        salvProMax: [],
        myColor: [],
      },
    }
  },
  components: {
    Chart,
  },
  mounted() {
    this.setData()
  },
  methods: {
    // 根据自己的业务情况修改
    setData() {
      // for (let i = 0; i < this.cdata.barData.length - 1; i++) {
      //   let rate = this.cdata.barData[i] / this.cdata.lineData[i]
      //   this.cdata.rateData.push(rate.toFixed(2))
    },
  },
}
</script>

<style lang="scss" scoped>
.twain {
  width: 100%;
  height: 100%;
}
</style>