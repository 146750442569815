<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:37:53
 * @LastEditTime: 2022-04-15 17:03:22
 * @FilePath: \cloud-platform\src\components\dashBoard\index.vue
 * @Description: 
-->
<template>
  <div
    id="dashBoard"
    class="dashBoard"
    :style="{ height: height, width: width }"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      height: '150px',
      width: '150px',
      options: {},
      chartData: [
        {
          name: '观察期',
          value: 122,
        },
        {
          name: '已行权',
          value: 986,
        },
      ],
      legends: {},
    }
  },
  mounted() {
    this.initCharts()
    // setInterval(() => {
    this.initChart()
    // }, 100)
  },
  methods: {
    initCharts() {
      this.chart = this.$echarts.init(this.$el)
    },
    initChart() {
      this.legends = this.chartData.map((data, index) => {
        return data.name
      })
      this.options = {
        title: {
          text: '{a|986}{b|%}',
          textStyle: {
            rich: {
              a: {
                fontSize: 12,
                color: '#fff',
                fontWeight: 'bold',
              },
              b: {
                fontSize: 12,
                color: '#fff',
              },
            },
          },
          left: 'center',
          bottom: 'middle',
        },
        legend: {
          bottom: 10,
          selectedMode: false,
          data: this.legends,
          icon: 'circle',
          itemStyle: {
            // color:['#3A71F0', '#e9f1f9']
          },
        },
        angleAxis: {
          max: 1200,
          axisLabel: false,
          axisTick: false,
          axisLine: false,
          splitLine: false,
        },
        radiusAxis: {
          type: 'category',
          data: ['1'],
          axisLabel: false,
          axisTick: false,
          axisLine: false,
        },
        polar: {
          center: ['50%', '50%'],
          // radius:['100%','80%']
        },

        series: [
          {
            // name: '已行权',
            type: 'bar',
            // stack: '已行权',
            coordinateSystem: 'polar',
            barWidth: 12,
            showBackground: true,
            roundCap: true,
            itemStyle: {
              color: '#3A71F0',
              borderRadius: [4, 4, 4, 4],
              backgroundColor: '#333',
              shadowBlur: 10,
              shadowColor: '#3A71F0',
            },
            backgroundStyle: {
              color: '#e9f1f9',
            },
            data: [986],
          },
        ],
      }
      this.chart.setOption(this.options, true)
      // 初始化echart
    },
  },
  beforeDestroy() {
    this.chart.dispose()
    this.chart = null
  },
}
</script>
<style scoped lang="scss">
</style>