<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:38:35
 * @LastEditTime: 2022-12-06 14:26:15
 * @FilePath: \cloud-platform\src\components\scrollBoard\index.vue
 * @Description: 
-->
<template>
  <div class="single">
    <dv-scroll-board :config="config" style="height: 242px" />
  </div>
</template>

<script>
import { currentTimeAccess } from '@/api/monitors.js'
export default {
  data() {
    return {
      timer: '',
      i: 1,
      information: [],
      config: {},
    }
  },
  components: {},
  mounted() {
    this.Throughput()
    this.timer = setInterval(this.Throughput, 60000)
  },
  methods: {
    async Throughput() {
      var gather = []
      var condition = ''
      await currentTimeAccess({ id: this.$store.getters.getdadavid }).then(
        (res) => {
          this.information = res.data.data
          for (let i = 0; i < this.information.length; i++) {
            var time = this.conversionTimeNew(this.information[i].inOutTime)
            switch (this.information[i].inoutFlag) {
              case 0:
                condition = '进入'
                break
              case 1:
                condition = '外出'
                break
            }
            var list = [
              `${this.information[i].pkName}`,
              '<span>' + this.information[i].plateNo + '</span>',
              `${time}`,
              `${condition}`,
            ]
            gather.push(list)
          }
          this.config = {
            waitTime: 1000,
            headerBGC: ['transparent'],
            oddRowBGC: ['transparent'],
            evenRowBGC: ['transparent'],
            header: ['停车场', '车牌', '时间', '状态'],
            data: gather,
            columnWidth: [300, 300, 300, 100],
            align: ['center', 'center', 'center', 'center'],
          }
        }
      )
    },
    conversionTimeNew(timestamp) {
      // chuan时间戳的转换
      var date = new Date(timestamp * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return MM + '/' + d + ' ' + h + ':' + m + ':' + s
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>


<style   lang="scss">
.dv-scroll-board {
  .header {
    .header-item {
      color: #568aea;
    }
  }
}
</style>
<style scoped lang="scss">
.single {
  height: 100%;
  width: 100%;
}
</style>