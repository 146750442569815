<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:38:42
 * @LastEditTime: 2022-04-29 13:11:59
 * @FilePath: \cloud-platform\src\views\Outerchain\realTimeTrading\index.vue
 * @Description: 
-->
<template>
  <div id="item">
    <dv-border-box-12>
      <div class="bgColorBlack">
        <div class="dFlex">
          <div class="ranking">
            <span class="text">实时进出场车辆</span>
          </div>
        </div>
        <div class="title">
          <scrollBoard />
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import scrollBoard from '@/components/scrollBoard'
export default {
  data() {
    return {}
  },
  components: {
    scrollBoard,
  },
}
</script>

<style lang="scss" scoped>
#item {
  height: calc(100% / 3);
  .bgColorBlack {
    color: #fff;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .dFlex {
      .ranking {
        .text {
          font-weight: 700;
        }
      }
    }
    .title {
      margin-top: 10px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
