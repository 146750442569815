<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:37:53
 * @LastEditTime: 2022-05-13 14:37:30
 * @FilePath: \cloud-platform\src\components\publicColumn\index.vue
 * @Description: 
-->
<template>
  <div class="twain">
    <Chart />
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  components: {
    Chart,
  },
}
</script>

<style lang="scss" scoped>
.twain {
  width: 100%;
  height: 100%;
}
</style>