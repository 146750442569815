
<template>
  <div id="item">
    <dv-border-box-12>
      <div class="bgColorBlack">
        <div class="dFlex">
          <div class="ranking">
            <span class="text">总交易金额</span>
          </div>
          <div class="title">
            <dv-border-box-8
              class="items"
              v-for="(item, index) in magnitudes"
              :key="index"
              >{{ item }}</dv-border-box-8
            >

            <dv-border-box-8 class="items">元</dv-border-box-8>
          </div>
          <div class="particular">
            <div class="countLeft">
              <div class="item">
                <div class="top">
                  <div class="items">
                    <p class="unit">
                      <span>{{ tradeDetails[0].totalOrderMoney }}</span
                      >元
                    </p>
                    <p>24小时交易金额</p>
                  </div>
                  <div class="items">
                    <p class="unit">
                      <span>{{ tradeDetails[0].totalOrderNum }}</span
                      >笔
                    </p>
                    <p>24小时交易笔数</p>
                  </div>
                </div>
                <div class="bottom">
                  <div class="items">
                    <p class="unit">
                      <span>{{ tradeDetails[1].totalOrderMoney }}</span
                      >元
                    </p>
                    <p>本周交易金额</p>
                  </div>
                  <div class="items">
                    <p class="unit">
                      <span>{{ tradeDetails[1].totalOrderNum }}</span
                      >笔
                    </p>
                    <p>本周交易笔数</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="pieRight">
              <pie class="items" />
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
import pie from '@/components/pie'
import { TransactionDetails } from '@/api/monitors.js'
export default {
  data() {
    return {
      tradeDetails: [
        { totalOrderMoney: 0, totalOrderNum: 0 },
        { totalOrderMoney: 0, totalOrderNum: 0 },
      ],
      allMoney: 0,
      magnitudes: [],
    }
  },
  created() {
    this.trade()
    this.timer = setInterval(this.trade, 21600000)
  },
  components: {
    pie,
  },
  methods: {
    async trade() {
      TransactionDetails({ id: this.$store.getters.getdadavid }).then((res) => {
        var list = res.data.data

        this.magnitudes = this.numToArray(list.allMoney)

        this.tradeDetails = list.pkDayTotalMonies
      })
    },
    numToArray(num) {
      var str = num + ''
      var resultArr = []
      for (var i = 0; i < str.length; i++) {
        resultArr.push(str[i])
      }
      return resultArr
    },
  },
}
</script>

<style lang="scss" scoped>
@media screen and(min-width: 1024px) {
  #item .particular .countLeft .item .items {
    font-size: 14px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 14px;
  }
} /*>=1024的设备屏幕*/

@media screen and(min-width: 1100px) {
  #item .particular .countLeft .item .items {
    font-size: 15px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 15px;
  }
} /*>=1100的设备屏幕*/
@media (min-width: 1280px) {
  #item .particular .countLeft .item .items {
    font-size: 16px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 16px;
  }
} /*>=1280的设备屏幕*/

@media screen and(min-width: 1366px) {
  #item .particular .countLeft .item .items {
    font-size: 17px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 17px;
  }
} /*>=1366的设备屏幕*/

@media screen and(min-width: 1440px) {
  #item .particular .countLeft .item .items {
    font-size: 18px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 18px;
  }
} /*>=1440的设备屏幕*/

@media screen and(min-width: 1680px) {
  #item .particular .countLeft .item .items {
    font-size: 19px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 19px;
  }
} /*>=1680的设备屏幕*/
@media screen and(min-width: 1920px) {
  #item .particular .countLeft .item .items {
    font-size: 20px;
  }
  #item .particular .countLeft .item .items .unit {
    font-size: 20px;
  }
} /*>=1920的设备屏幕*/

p {
  margin: 0;
  padding: 0;
}
#item {
  height: calc(100% - 62%);
  .bgColorBlack {
    color: #fff;
    padding: 20px 20px;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    .dFlex {
      display: flex;
      flex-direction: column;
      height: 100%;
      .ranking {
        text-align: center;
        .text {
          font-weight: 700;
        }
      }
    }
    .title {
      margin-top: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      .items {
        width: 20px;
        height: 50px;
        line-height: 50px;
        padding: 0 10px;
        text-align: center;
        margin-left: 10px;
        color: gold;
        font-size: 20px;
        font-weight: 800;
      }
    }
    .particular {
      display: flex;
      flex: 1;
      .countLeft {
        flex: 1;
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          .top,
          .bottom {
            flex: 1;
          }
          .items {
            text-align: center;
            margin: 0 5px;

            .unit {
              color: #d7c817;
              span {
                color: #fff007;
                font-weight: 800;
                font-size: 1.2em;
                margin: 0 5px;
              }
            }
            p {
              font-size: 0.9em;
              margin: 2px 0;
              &:last-child {
                font-size: 0.5em;
              }
            }
          }
        }
      }
      .pieRight {
        flex: 1;
        display: flex;
        .items {
          flex: 1;
        }
      }
    }
  }
}
</style>
