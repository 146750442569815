<!--
 * @Author: sunfei
 * @Date: 2022-04-14 12:37:53
 * @LastEditTime: 2022-12-06 14:31:18
 * @FilePath: \cloud-platform\src\components\pie\index.vue
 * @Description: 
-->
<template>
  <div class="twain">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data() {
    return {
      cdata: {
        category: [],
        barData: [],
        rateData: [],
        salvProMax: [],
        myColor: [],
      },
    }
  },
  components: {
    Chart,
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.twain {
  height: 100%;
  width: 100%;
}
</style>