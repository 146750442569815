<!--
 * @Author: sunfei
 * @Date: 2022-04-14 09:54:59
 * @LastEditTime: 2022-12-07 10:58:19
 * @FilePath: \cloud-platform\src\views\Outerchain\Outerchain.vue
 * @Description: 
-->
<template>
  <div id="bigScreen">
    <i class="el-icon-rank qp" @click="qpClick"></i>
    <div class="hostBody">
      <div class="header">
        <dv-decoration-10 class="headerLeft" style="height: 5px" />
        <div class="headerCenter">
          <dv-decoration-8 class="left" :color="decorationColor" />
          <div class="center">
            <div class="title">平 治 停 车 云 平 台</div>

            <div class="presentTime">{{ systemTime }}</div>
          </div>
          <dv-decoration-8
            :reverse="true"
            class="right"
            :color="decorationColor"
          />
        </div>
        <dv-decoration-10 class="headerRight" :reverse="true" />
      </div>
      <!-- <div class="bsCenter">
        <div class="bsTop">
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <div class="flow">
                  <onTheSameDayflow />
                </div>
                <div class="pay">
                  <onTheSameDaypay />
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <Total></Total>
            </el-col>
            <el-col :span="6">
              <realTimeTrading />
              <realTimeTradings />
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="bsbottom">
        <div class="pay">
          <el-row>
            <el-col :span="6">
              <div class="grid-content bg-purple">
                <onTheSameDayThroughput />
              </div>
            </el-col>
            <el-col :span="12">
              <totalAmount />
            </el-col>
            <el-col :span="6"><realTimeRecord /></el-col>
          </el-row>
        </div>
      </div> -->
      <div class="center">
        <div class="bsLeft">
          <!-- 今日车流量统计 -->
          <onTheSameDayflow />
          <!-- 昨日缴费排行 -->
          <onTheSameDaypay />
          <!-- 昨日车流量排行 -->
          <onTheSameDayThroughput />
        </div>
        <div class="bsCenter">
          <!-- 地图 -->
          <Total></Total>
          <!-- 总交易金额 -->
          <totalAmount />
        </div>
        <div class="bsRight">
          <!-- 实时进出场车辆 -->
          <realTimeTrading />
          <!-- 实时交易 -->
          <realTimeTradings />
          <!-- 近7天交易分析 -->
          <realTimeRecord />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onTheSameDayflow from './onTheSameDayflow'
import onTheSameDaypay from './onTheSameDaypay'
import onTheSameDayThroughput from './onTheSameDayThroughput'
import realTimeRecord from './realTimeRecord'
import realTimeTrading from './realTimeTrading'
import realTimeTradings from './realTimeTradings'
import Total from './total'
import totalAmount from './totalAmount'
export default {
  // 组件名称
  name: 'Outerchain',
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {
    onTheSameDayflow,
    onTheSameDaypay,
    onTheSameDayThroughput,
    Total,
    realTimeTrading,
    realTimeTradings,
    realTimeRecord,
    totalAmount,
  },
  // 组件状态值
  data() {
    return {
      decorationColor: ['#568aea', '#000000'],
      systemTime: '',
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    qpClick() {
      // 全屏
      var _this = this
      var el = document.documentElement
      if (document.fullscreenElement === null) {
        _this.openFullscreen(el)
      } else {
        _this.quitFullscreen()
      }
    },
    openFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen()
      }
    },
    quitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    getKeyDown(e) {
      var _this = this
      if (e.keyCode === 122) {
        e.preventDefault() || (e.returnValue = false)
        _this.toggleFullscreen() // 触发全屏的按钮
      } else if (e.keyCode === 27) {
        if (document.fullscreenElement !== null) {
          _this.quitFullScreen()
        }
      }
    },
    getNowTime() {
      var date = new Date()
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear() //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1
      //日 getDate()：(1 ~ 31)
      var day = date.getDate()
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours()
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes()
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds()
      var time =
        year +
        '-' +
        this.addZero(month) +
        '-' +
        this.addZero(day) +
        ' ' +
        this.addZero(hour) +
        ':' +
        this.addZero(minute) +
        ':' +
        this.addZero(second)
      this.systemTime = time
    },
    //小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? '0' + s : s
    },
  },
  created() {
    setInterval(() => {
      this.getNowTime()
    }, 1000)
  },
}
</script> 
<style scoped lang="scss">
@media all and (min-width: 768px) {
  #bigScreen .hostBody .header .headerCenter .center {
    font-size: 13px;
  }
}
@media all and (min-width: 1024px) {
  #bigScreen .hostBody .header .headerCenter .center {
    font-size: 16px;
  }
}
@media all and (min-width: 1580px) {
  #bigScreen .hostBody .header .headerCenter .center {
    font-size: 20px;
  }
}

#bigScreen {
  height: 100%;
  overflow: hidden;
  background: #000;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  .qp {
    position: absolute;
    right: 4%;
    top: 3%;
    font-size: 50px;
    color: #fff;
    transform: rotate(45deg);
  }
  .hostBody {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      background-size: contain;
      display: flex;
      .headerLeft {
        width: calc(100% / 3);
      }
      .headerCenter {
        display: flex;
        flex: 1;
        .left {
          width: 25%;
          height: 50px;
        }
        .center {
          width: 50%;
          display: flex;
          flex-direction: column;
          color: #fff;
          text-align: center;
          .title {
            position: relative;
            top: 0px;
            min-width: 100px;
            font-size: 1.4em;
          }
          .presentTime {
            font-size: 0.9em;
            margin-top: 5px;
          }
        }

        .right {
          width: 25%;
          height: 50px;
        }
      }
      .headerRight {
        width: calc(100% / 3);
        height: 5px;
        transform: rotateY(180deg);
      }
    }
    .center {
      display: flex;
      justify-content: space-between;
      .bsLeft {
        width: 28%;
      }
      .bsCenter {
        width: 44%;
      }
      .bsRight {
        width: 28%;
      }
    }
  }
}
</style>
